import { Component, OnInit, AfterViewChecked,AfterViewInit,HostListener,NgZone,ViewChild, OnDestroy, Inject, ElementRef, ChangeDetectorRef } from '@angular/core';
import { environment } from '../environments/environment';
import {Event, Router, ActivatedRoute, NavigationEnd,RoutesRecognized , RouterEvent, NavigationCancel, NavigationStart, NavigationError } from '@angular/router';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { MatDialogConfig, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { filter, map, mergeMap } from 'rxjs/operators';
import { ApplicationService } from './services/application.service';
import { RscClientService } from './services/rsc-client.service';
import { SidenavState } from './shared/models/sidenav-state.model';
import { UserService } from './core/services/user.service';
import {  FeatureFlagsService } from './core/launchdarkly';
//import { Logger } from './core/logger';
import {  AppService} from './core/services/app.service';
import { OktaAuthenticationProvider } from './core/auth-providers/okta-authentication.provider';
import { AuthenticationService } from './core/auth/authentication.service';
import { CoreService } from './services/core.service';
import {MaterialModule } from './shared/material.module'
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import '@abt-desk/rsc/lib/es2015';
import '@abt-desk/rsc/lib/es2015/speed-dial';
import '@abt-desk/rsc/lib/es2015/side-nav';
import '@abt-desk/rsc/lib/es2015/header';
import '@abt-desk/rsc/lib/es2015/advanced-footer';
import { JsonPipe } from '@angular/common';

declare const gtag: Function;

function _window(): any {
  return window;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  title = 'iProspect';
  loading = true;
  sub: Subscription;
  minCountDown = 0;
  secCountDown = 0;
  idleState = 'NOT_STARTED';
  timedOut = false;
  router$: Subscription;

  private rscClient: any;
  public mounted = false;
  public isSetMenu = false;
  user;

  mobile: boolean;
  tablet: boolean;
  isAnonymous = false;
  pagePath: string;
  urlLocation: string;
  brand_key: string;
  brand_key_header: string;
  logoutUX$: Subscription;
  logoutUXOn = false;
  onSigning = false;
  userIdentities: any;
  coreUser: any;
  isNavOpen: Boolean;
  keepClose:Boolean;
  @ViewChild('rscHeader', { read: ElementRef }) rscHeader: ElementRef;

  
  constructor(private element: ElementRef
    , private router: Router
    , private activatedRoute: ActivatedRoute
    , private changeDetectorRef: ChangeDetectorRef
    , public dialog: MatDialog
    ,private ngZone: NgZone
    ,private authService: OktaAuthenticationProvider
    ,private authenticationService: AuthenticationService
    ,private route: Router
    ,private appService: ApplicationService
    ,private rscClientService: RscClientService
    ,private userService: UserService
    ,private featureFlagsService: FeatureFlagsService
    , private coreService: CoreService
    ,private matIconRegistry: MatIconRegistry
    ,private domSanitizer: DomSanitizer) {

      // this.matIconRegistry.addSvgIcon("handshake", this.domSanitizer.bypassSecurityTrustResourceUrl("handshake.svg")); 
      // this.matIconRegistry.addSvgIcon("quatile", this.domSanitizer.bypassSecurityTrustResourceUrl("quatile.svg"));
      // this.matIconRegistry.addSvgIcon("PhotoCameraFrontOutlined", this.domSanitizer.bypassSecurityTrustResourceUrl("PhotoCameraFrontOutlined.svg"));
    this.router.events.subscribe((event)=> {
    if (event instanceof NavigationStart) {
      this.loading = true;

    }
    if (AppService.get('gaTrackingId')) {
      // register google tag manager
      const gTagManagerScript = document.createElement('script');
      gTagManagerScript.async = true;
      gTagManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=${AppService.get('gaTrackingId')}`;
      document.head.appendChild(gTagManagerScript);
  
      // register google analytics
      const gaScript = document.createElement('script');
      gaScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag() { dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', '${AppService.get('gaTrackingId')}');
      `;
      document.head.appendChild(gaScript);
    }
    if (event instanceof NavigationEnd) {
      this.loading = false;
      this.changeDetectorRef.detectChanges()
      //gtag('config', 'G-LBXHE03P9M', { 'page_path': event.urlAfterRedirects })
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.loading = false;
    }
    if (event instanceof NavigationError) {
      this.loading = false;
    }
  })
}

  ngOnInit() {
    document.getElementById('iProspectApplicationBody').setAttribute('data-apm-env', AppService.get('apmEnvironment'));
    //this.featureFlagsService.initLaunchDarkly();
    this.ngZone.run(() => (this.mounted = true));
    this.urlLocation = window.location.origin;
    if(localStorage.getItem('brand_key')){
      document.getElementById('app-body').style.display = 'block';
      this.brand_key = localStorage.getItem('brand_key');
      if(localStorage.getItem('brand_key')==='sir'){
        this.brand_key_header = 'sothebys';
      }else if(localStorage.getItem('brand_key')==='cor'){
        this.brand_key_header = 'corcoran';
      }else{
        this.brand_key_header = localStorage.getItem('brand_key');
      }
      localStorage.removeItem('brand_key');
    }
    this.authService
      .isAuthenticatedObs()
      .pipe(
        map((authenticated) => {
          const token = this.authService.getToken();
          if (token) {
            this.rscClient.setAccessToken(token.accessToken);
          }
          return authenticated;
        })
      )
      .subscribe((userData) => {
        if (userData) {
          //console.warn(Logger.generateInfo('warning message','infoLog','app.components',userData));
        }
      });

    this.router$ = this.route.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd 
        )
      )
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.pagePath = event.url;
          if (this.appService.getIsTouchDevice()) {
            this.rscClientService.toggleSideNav(false);
          }
          this.rscClientService.setCurrentUrl(event.url);
        }
      });

      this.router$ = this.route.events
      .pipe(
        filter(
          (event): event is RoutesRecognized =>
            event instanceof RoutesRecognized
        )
      )
      .subscribe((event) => {
        if (event instanceof RoutesRecognized) {
          const routeData = event.state.root.firstChild.data;

          if (routeData) {
            if (this.isAnonymous !== routeData.anonymous) {
              this.isAnonymous = routeData.anonymous;
              this.setSidenavState();
            }
            this.onSigning = routeData.onSigning;
          }
        }
      });
  }

  ngAfterViewInit() {
    this.rscClient = _window().__RSC__;
    const sidenavEl = document.getElementsByTagName("rsc-side-nav")[0];
    
    sidenavEl.addEventListener('rscSidenavOpened', () => {
      this.isNavOpen=true; 
    });

    sidenavEl.addEventListener('rscSidenavClosed', () => {
      this.isNavOpen=false;
    });
    this.rscHeader?.nativeElement?.setAttribute('brand-key', this.brand_key_header);
    this.authService.getUser().subscribe((user) => {
      if (user) {
        if (this.rscClient && !this.isSetMenu && this.authService.userName) {
          const accessToken = this.authService.getToken();
          if (accessToken) {
            this.rscClient.setAccessToken(accessToken.accessToken);
          }
          this.rscClient.setUsername(this.authService.userName);
          this.rscClient.setOktaId(this.authService.oktaId);
          this.rscClient.setGlobalTheme(this.brand_key);
          if(this.brand_key == undefined || this.brand_key == ''){
            this.userIdentities = this.authService.getUserIdentities(this.authService.userId,this.authService.orgId);
          
            this.userIdentities.subscribe((res)=>{
              if(res){
                localStorage.setItem('brand_key',res.userAttributes.userBrands[0].toLowerCase());
                
                window.location.reload();
              }
            });
          }else{
            document.getElementById('app-body').style.display = 'block';
            this.coreUser=this.coreService.getUserAccount(this.authService.userId);
            this.coreUser.subscribe((data:any)=>{
              console.log('User found: ');
              console.log(data);              
            },
            (error) => {
              if (error.status === 404) {
                this.coreService.upsertUserAccount(this.authService.userId, this.authService.email).subscribe((data)=>{
                  console.log('User upserted: ');
                  console.log(data);
                }, (err) => {
                  console.log('Error upserting: ');
                  console.log(err);
                });
              }
            });
          }
          
          this.rscClient.showUserNameInTopnav(true);
          this.rscClient.showWaffleMenu(false);
          this.rscClient.setHomeButtonCallback(()=>{this.router.navigate(['/'])});

          this.isSetMenu = true;
          this.rscClient.setMenuItem({
            name: 'My Profile',
            icon: 'far fa-user',
            callback: () => {
              this.redirectToProfile()
            },
          });
          this.rscClient.setMenuItem({
            name: 'Settings',
            icon: 'fal fa-cog',
            callback: (() => this.route.navigate(['settings'])).bind(this),
          });

          this.rscClient.setMenuItem({
            name: 'Sign Out',
            icon: 'fal fa-sign-out-alt',
            callback: () => {
              this.redirectToLogout();
            },
          });
          this.rscClient.setSpeedDialOption({
            name: 'Access Job Aids',
            icon: 'fal fa-circle-question',
            url: 'https://iprospect.realogyfg.com/sites/help/Pages/',
            target: '_blank'
        });
          this.rscClient.setSpeedDialOption({
            name: 'Call/Request Help',
            icon: 'fal fa-ticket',
            url: 'https://realogy.service-now.com',
            target: '_blank'
        });
          this.rscClient.setSpeedDialOption({
            name: 'Reset Password',
            icon: 'fal fa-key',
            url: 'https://realogy.okta.com/enduser/settings',
            target: '_blank'
        });
          this.rscClient.setSpeedDialOption({
            name: 'Leave Feedback',
            icon: 'fal fa-comment-exclamation',
            url: '/feedback',
            target: '_self'
        });

          this.rscClient.setSidenav([
            // {
            //   //url:'/home',
            //   icon: 'navArrow',
            //   label: '',
            //   callback: (() => { setTimeout(this.rscClient.toggleSidenavOpenState(), 100) }),
            // },
            {
              //url:'/home',
              icon: 'navHome',
              label: 'Welcome, '+this.authService.userName,
              //callback: (() => this.route.navigate(['/home'])).bind(this),
            },
            {
              icon: 'navSearch',
              label: 'Search MLS Records',
              url: '/searchmls',
              callback: (() => this.setNavigation('/searchmls')).bind(this),
              childItems: [],
            },
            {
              icon: 'navProspect',
              label: 'My Prospects',
              url:'/myprospects',
              callback: (() => this.setNavigation('/myprospects')).bind(this),
              childItems: [],
            },
            {
              icon: 'navAgent',
              label: 'My Agents',
              url:'/myagents',
              callback: (() => this.setNavigation('/myagents')).bind(this),
              childItems: [],
            },
            {
              icon: 'navReport',
              label: 'Reports',
              url:'/reports',
              callback:(()=> this.rscClient.setCurrentUrl("/reports")),
              childItems: [{
                url:'/reports/summary',
                label: 'Summary Report',
                callback: (() => this.setNavigation('/reports/summary')).bind(this),
                childItems: [],
              },{
                url:'/reports/detail',
                label: 'Detail Report',
                callback: (() => this.setNavigation('/reports/detail')).bind(this),
                childItems: [],
              },{
                url:'/reports/attractiontool',
                label: 'Attraction Tool',
                callback: (() =>   this.setNavigation('/reports/attractiontool')).bind(this),
                childItems: [],
              }],
            }
          ]);
        }
        this.setSidenavState();
      }
    });

      this.logoutUX$ = this.userService.isLogoutUX().subscribe(logoutUX => {
        if (logoutUX) {
          const appBody = document.getElementById('app-body');
          appBody.classList.add('logout-experience');
          appBody.classList.remove('login-experience');
          setTimeout(() => {
            this.logoutUXOn = logoutUX;
            this.setSidenavState();
            setTimeout(() => {
              appBody.classList.remove('logout-experience');
            }, 500);
          }, 200);
        }
      });

  }

  redirectToLogout() {
    this.userService.setLogoutUX(true);
    this.rscClient.showWaffleMenu(false);
    this.rscClient.setHelpDocsUrl('');
    this.rscClient.setUser(null);
    this.rscClient.setMenu([]);
    this.ngZone.run(() => {
    setTimeout(() => {
      this.route.navigate(['logout']);
     }, 200);
    });
  }

  redirectToProfile() {
    this.ngZone.run(() => {
      this.route.navigate(['/searchmls']);
    });
  }

  setNavigation(state:string){
    this.route.navigate([state]);
    this.rscClient.setCurrentUrl(state);
    if(!this.isNavOpen){
    setTimeout(() => {
      this.rscClient.toggleSidenavOpenState();
    }, 100);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.onDeviceChange(event);
    this.setSidenavState();
  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationchange(event) {
    this.onDeviceChange(event);
  }

  onDeviceChange(event?) {
    const widthToSend = event ? event.target.innerWidth : window.innerWidth;
    this.appService.setScreenWidth(widthToSend);
    this.mobile = this.appService.isPhoneDevice();
  }

  setSidenavState() {
    
    const navState = new SidenavState();
      if (this.mobile || (this.tablet && window.innerWidth < 992)) {
        navState.deserialize({
          enableCache: false,
          enableStaggeredNav: false,
          state: 'closed',
          hasBackdrop: false,
          disableHover: true
        });
      } else if (this.isAnonymous || this.logoutUXOn || this.onSigning ) {
        navState.deserialize({
          enableCache: true,
          enableStaggeredNav: false,
          state: 'closed',
          hasBackdrop: false,
          disableHover: true
        });
      } else {
        navState.deserialize({
          enableCache: false,
          enableStaggeredNav: true,
          state: 'closed',
          hasBackdrop: false,
          disableHover: true
        });
      }
    this.rscClientService.setSidenavState(navState);
  }

  
  ngOnDestroy() {
    if (this.router$) { this.router$.unsubscribe(); }
    if (this.logoutUX$) { this.logoutUX$.unsubscribe(); }
  }

  // colapseNav(){
  //   if(this.isNavOpen){
  //     this.rscClient.toggleSidenavOpenState();
  //   }
  // }
}
