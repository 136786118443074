import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER, NO_ERRORS_SCHEMA,} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { routing } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { MyAgentsModule } from './myagents/myagents.module';
import { SettingsModule } from './settings/settings.module';
import { SearchmlsModule } from './searchmls/searchmls.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MaterialModule} from './shared/material.module';
import { SnackbarComponent } from './snackbar/snackbar.component';
import { SnackbarService } from './services/snackbar.service';
import { OktaLoginComponent } from './authentication/okta/okta-login/okta-login.component';
import { OktaCallbackComponent } from './authentication/okta/okta-callback/okta-callback.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { effects } from './core/store/effects';
import { reducers } from './core/store/reducers';
import { ApplicationService } from './services/application.service';
import { RscClientService } from './services/rsc-client.service';
import { LogoutComponent } from './logout/logout.component';
import {   StartupService } from './core/services/startup.service';
import {  initializeApp } from './core/services/startup.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './login/login.component';
import { AppLoadModule } from './app-load.module';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Interceptor } from './services/interceptor.service';
import { CannyService } from './services/canny.service';
import { RouteResolver } from './services/routeResolver';
import { NgChartsModule } from 'ng2-charts';
import { ConfirmDialogComponent } from './shared/modal/confirm-dialog/confirm-dialog.component';
import { MyProspectsModule } from './myprospects/myprospects.module';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CustomPaginator } from './core/providers/customPaginatorConfiguration';
import { ClickOutsideModule } from 'ng-click-outside';

@NgModule({
  declarations: [
    AppComponent,
    SnackbarComponent,
    LoginComponent,
    LogoutComponent,
    OktaCallbackComponent,
    OktaLoginComponent,
    ConfirmDialogComponent,
  ],
  imports: [
    BrowserModule,
    routing,
    NgChartsModule,
    CoreModule,
    SharedModule,
    DashboardModule,
    SettingsModule,
    MaterialModule,
    CommonModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppLoadModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot(effects),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
    }),
    ClickOutsideModule
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [StartupService], multi: true},
  {
    provide: HTTP_INTERCEPTORS,
    useClass: Interceptor,
    multi: true,
  },
  { provide: MatPaginatorIntl, useValue: CustomPaginator() },
  RouteResolver,
  SnackbarService,
  ApplicationService,
  RscClientService,
  CannyService],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
