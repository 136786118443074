import { NgModule, ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SettingsComponent } from '../app/settings/settings.component';
import { AuthGuard } from './core/guards/auth.guard';
import { OktaLoginComponent } from './authentication/okta/okta-login/okta-login.component';
import { OktaCallbackComponent } from './authentication/okta/okta-callback/okta-callback.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { FeedbackComponent } from './feedback/feedback.component';

export const appRoutes: Routes = [
  { path: '', redirectTo: '/myprospects', pathMatch: 'full' },
  {
    path: 'home',
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'myagents',
    loadChildren: () =>
      import('./myagents/myagents.module').then((m) => m.MyAgentsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'myprospects',
    loadChildren: () =>
      import('./myprospects/myprospects.module').then((m) => m.MyProspectsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'searchmls',
    loadChildren: () =>
      import('./searchmls/searchmls.module').then((m) => m.SearchmlsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'reports',
    loadChildren: () =>
      import('./reports/reports.module').then((m) => m.ReportsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'okta/login',
    component: OktaLoginComponent,
    data: { onSigning: true },
  },
  { path: 'login/callback', component: OktaCallbackComponent },
  { path: 'implicit/callback', component: OktaCallbackComponent },
  { path: 'login', component: LoginComponent, canActivate: [AuthGuard] },
  { path: 'logout', component: LogoutComponent, data: { footerVisible: false } },
  {
    path: 'feedback',
    component: FeedbackComponent,
    canActivate: [AuthGuard],
    children: [{
      path: '*',
      component: FeedbackComponent
    }]
  },
];

export const routing: ModuleWithProviders<any> = RouterModule.forRoot(
  appRoutes,
  {
    onSameUrlNavigation: 'reload',
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy',
  }
);
