<div class="flex">
        <h3>Multi-Year Trends</h3>
        <div class="flex-item">
<canvas baseChart class="bgChart"
        [data]="barChartData"
        [options]="barChartOptions"
        [plugins]="barChartPlugins"
        [legend]="barChartLegend">
</canvas>
</div>
</div>