import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { CoreFabricService } from '../interfaces/core-fabric.service';
import { User } from '../../models/user';

@Injectable()
export class UserService implements CoreFabricService {
  private postLoginUXState = new BehaviorSubject<boolean>(false);
  public isImpersonating = new BehaviorSubject<boolean>(false);

  constructor(
    protected httpClient: HttpClient,
  ) {
 
  }

  public isLogoutUX(): Observable<boolean> {
    return this.postLoginUXState.asObservable();
  }

  public setLogoutUX(state) {
    this.postLoginUXState.next(state);
  }
 
  public get(): User {
    throw new Error('Method not implemented.');    
  }
  
  public update(): User {
    throw new Error('Method not implemented.');
  }
  
  public delete(): User {
    throw new Error('Method not implemented.');
  }
  
  public post(): User {
    throw new Error('Method not implemented.');
  }
  
  public search(): Array<User> {
    throw new Error('Method not implemented.');
  }
}
