import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SidenavItem } from '../shared/models/sidenav-item.model';
import { SidenavState } from '../shared/models/sidenav-state.model';
//import { SpeedDialOption } from '../shared/models/speed-dail-option.model';

declare global {
  interface Window { __RSC__: any; }
}

@Injectable({
  providedIn: 'root'
})
export class RscClientService {

  private isNavOpen = false;

  public rscClient;
  public isRscServiceReady = new BehaviorSubject<boolean>(false);
  public isNavOpen$ = new BehaviorSubject<boolean>(false);

  // { url: pattern }
  public urlExceptions = {
  };

  constructor() {
    this.setRscClient();
  }

  setRscClient() {
    if (window.__RSC__) {
      this.rscClient = window.__RSC__;
      this.isRscServiceReady.next(true);
    } else {
      window.addEventListener('rscServiceInit', (ev) => {
        this.rscClient = window.__RSC__;
        this.isRscServiceReady.next(true);
      });
    }
  }

  // Helper methods

  listenToSidenavEvents() {
    const sidenavEl = document.getElementsByTagName('rsc-side-nav')[0];

    sidenavEl?.addEventListener('rscSidenavClosed', () => {
      this.setIsSidenavOpen(false);
    });

    sidenavEl?.addEventListener('rscSidenavOpened', () => {
      this.setIsSidenavOpen(true);
    });
  }

  setIsSidenavOpen(state: boolean) {
    this.isNavOpen = state;
    this.isNavOpen$.next(state);
  }

  isSidenavOpen(): boolean {
    return this.isNavOpen;
  }

  isSidenavOpenObservable() {
    return this.isNavOpen$;
  }

  // Methods accessing rsc client

  // setSpeedDial(options: Array<SpeedDialOption>) {
  //   this.rscClient.setSpeedDial(options);
  // }

  setSidenav(sidenavItems: Array<SidenavItem>) {
    this.rscClient.setSidenav(sidenavItems);
  }

  setSidenavState(state: SidenavState) {
    this.rscClient.setSidenavState(state);
  }

  setCurrentUrl(url) {
    url = this.sanitizeURL(url);

    this.rscClient.setCurrentUrl(url);
  }

  sanitizeURL(url): string {
    let match: string;

    for (const key in this.urlExceptions) {
      if (url.match(this.urlExceptions[key])) {
        match = key;
        break;
      }
    }

    return match?.length ? match : url;
  }

  toggleSideNav(isOpen) {
    this.rscClient.setSidenavState({
      state: isOpen ? 'open' : 'closed',
    });
  }
}
