import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import * as fromStore from '../reducers/index';
import * as appsActions from '../actions/app.actions';

@Injectable()
export class AppsEffects {

    constructor(
        private actions$: Actions,
        private appStore$: Store<fromStore.AppState>,
    ) { }
}
