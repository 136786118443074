import { Action } from '@ngrx/store';

export const GET_REGISTERED_APPS = '[Apps] Get Registered Apps';
export const UPDATE_REGISTERED_APPS = '[Apps] Update Registered Apps';
export const UPDATE_REGISTERED_APPS_SUCCESS = '[Apps] Update Registered Apps Success';

export class GetRegisteredApps implements Action {
    readonly type = GET_REGISTERED_APPS;
}

export class UpdateRegisteredApps implements Action {
    readonly type = UPDATE_REGISTERED_APPS;
}

export class UpdateRegisteredAppsSuccess implements Action {
    readonly type = UPDATE_REGISTERED_APPS_SUCCESS;
    constructor(public payload: any) { }
}

export type AppAction = GetRegisteredApps
    | UpdateRegisteredApps
    | UpdateRegisteredAppsSuccess;
