import { Component, OnInit, Inject, ChangeDetectionStrategy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { ProspectsService } from './prospects.service';
import { Observable } from 'rxjs';
import { Prospect } from '../models/prospect';
import { ActivatedRoute } from '@angular/router'
@Component({ 
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardComponent  implements OnInit {
  prospect:Prospect; 
  iSLoaded:Boolean=false;
  private productsObservable : Observable<Prospect> ; 
  constructor(@Inject(DOCUMENT) private document: any
    ,  private titleService: Title,private activatedRoute:ActivatedRoute, private prospectService: ProspectsService ) {
    this.titleService.setTitle("iProspect Dashboard");
    this.activatedRoute.data.subscribe(user=>{
      //this.brand_key='c21';
    })
  }

  ngOnInit() {
  //   this.prospectService.getProspect().subscribe((res : Prospect)=>{
  //     this.prospect = res;
  //     this.iSLoaded=true;
  // });
    //console.log(this.prospect);
  }
}
