import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MaterialModule } from "./material.module";
import { TranslateModule } from "@ngx-translate/core";
import { NgSelectModule } from "@ng-select/ng-select";
import { RouterModule } from "@angular/router";
import { ProductionGraphComponent } from "./productiongraph/production.graph.component";
import { NgChartsModule } from "ng2-charts";
import { MinMaxComponent } from './modal/min-max/min-max.component';
import { ShortNumberPipe } from './pipes/short-number.pipe';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    TranslateModule,
    NgSelectModule,
    RouterModule,
    NgChartsModule
  ],
  exports: [
    CommonModule,
    TranslateModule,
    NgSelectModule,
    MaterialModule,
    ProductionGraphComponent,
    ShortNumberPipe
  ],
  declarations: [ProductionGraphComponent, MinMaxComponent, ShortNumberPipe
  ],
  providers: [ 
    ],
})
export class SharedModule {}
