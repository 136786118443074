import { HttpClient, HttpBackend } from '@angular/common/http';
import { combineLatest, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
//import { DataDogLogger, Logger } from '../../index';


export class brandConfig {
  bhgrealestate: { brandCode: string, idp:string};
  centruy21: { brandCode: string, idp:string};
  coldwellbanker: { brandCode: string, idp:string};
  cbcworldwide: { brandCode: string, idp:string};
  corcoran: { brandCode: string, idp:string};
  era: { brandCode: string, idp:string};
  sothebysrealty: { brandCode: string, idp:string};
  default: { brandCode: string, idp:string};
}


/**
 * Private class that is not exposed. Must get values from the AppService.
 * All variables are initialized with default values so that te Object.keys(...)
 * locates all valid variables
 */
 class AppConfig {
  applicationKey: string = null;
  production: boolean = false;
  apiBaseURL: string = null;
  securityApiURL: string = null;
  OcpApimSubscriptionKey: string = null;
  unsupportedBrowserUrl: string = null;
  snackbarTimeOut: number = 2000;
  oktaAuthEnabled: boolean = true;
  oktaTenantUrl: string = null;
  oktaIssuer: string = null;
  oktaClientId: string = null;
  oktaMaxClockSkew: string = null;
  oktaRedirectPath: string = null;
  launchdarklyConfig: {clientId: string} = null;
  brandConfig: brandConfig = null;
  applicationName: string = null;
  enableConsoleLog: boolean = false;
  mobileWidthMax: number = 470;
  tabletWidthMax: number = 1024;
  environment: string = null;
  apmEnvironment: string = null;
  dataDogConfig: {clientToken: string, url: string} = null;
  cannySsoUrl: string = null;
  cannyBoardToken: string = null;
  gaTrackingId: string = null;
  coreApiURL: string = null;
  coreApiKey: string = null;
  coreAppPoolId: string = null;
  pbcApiURL: string = null;
  pbcApiKey: string = null;
}

export class AppService {
  /**
   * App Configuration that is readonly and not exposed. Must be accessed via the
   * AppService static properties to prevent modifications
   */
  private static config: AppConfig;

  static get<TValueType = any>(propertyName: keyof AppConfig): TValueType {
    if(this.config == null) {
      //console.error(Logger.generateError('App has not been initialized. Make sure to run the APP_INITIALIZER', 'errorLog','app service', ''));
      throw Error('App has not been initialized. Make sure to run the APP_INITIALIZER');
    }
    const value: any = this.config[propertyName];
    return value;
  }

  /**
   * If the appConfig has not been loaded, load the config
   */
  public static initialize(backend: HttpBackend): Promise<void> {
    if(this.config != null) {
      //console.error(Logger.generateError('AppService can only be initialized once. Use static properties instead!', 'errorLog','app service', ''));
      throw Error('AppService can only be initialized once. Use static properties instead!')
    }

    // If the AppService has not been initialized, load up the configuration values and set the AppConfig
    // properties accordingly
    const http = new HttpClient(backend);

    // app configuration
     const config$ = http.get('./assets/configs/config.json').pipe(catchError(err => {
      console.log('Error loading base configuration! Using empty configuration');
      //console.error(Logger.generateError('Error loading base configuration! Using empty configuration', 'errorLog','app service', ''));
      return of({});
    }));

    return combineLatest([config$]).pipe(
      map(([config] )=> this.initializeAppConfig(config))
    ).toPromise()
  }

  /**
  * Initializing the AppConfig
   */
  private static initializeAppConfig(config: any) {
    this.config = config;

    // DataDog logger intialization should be based on enviroonment
    if (location.hostname !== 'localhost' && location.hostname !== 'remotedev') {
      //DataDogLogger.initialize(this.config.dataDogConfig.clientToken);
    }
  }

}
