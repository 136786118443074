import * as fromOkta from '../actions/okta.actions';
import * as _ from 'lodash';

export interface OktaState {
    token: {
        accessToken: any,
        idToken: any,
    };
}

const initialState = {
    token: {
        accessToken: null,
        idToken: null,
    }
};

export function oktaReducers(state = initialState, action: fromOkta.OktaAction): OktaState {

    switch (action.type) {
        case fromOkta.UPDATE_ACCESS_TOKEN: {

            const token = {
                accessToken: action.payload,
                idToken: state.token.idToken,
            };

            return {
                ...state,
                token: token,
            };
        }

        case fromOkta.UPDATE_ID_TOKEN: {

            const token = {
                accessToken: state.token.accessToken,
                idToken: action.payload,
            };

            return {
                ...state,
                token: token,
            };
        }

        default: {
            return state;
        }
    }
}
