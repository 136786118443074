<div class="contentPage" ><h2>Welcome to iProspect Dashboard</h2>
  <!-- <div *ngIf="!this.iSLoaded">Loading Prospect Data</div>
  <div >
    <h3>Prospect Data</h3>
  <mat-label>Prospect Name: </mat-label>
  <mat-hint align="end">{{prospect.prospectName}} </mat-hint>
  <br/>
  <mat-label>Prospect Price: </mat-label>
  <mat-hint align="end">{{prospect.price}} </mat-hint>
  <br/>
  <mat-label>Prospect ID: </mat-label>
    <mat-hint align="end">{{prospect.prospectId}} </mat-hint>
  </div>
  <br/>
  <br/> -->
    <mat-form-field appearance="outline">

        <mat-label>Last Name</mat-label>
        
        <input matInput #nickname placeholder="Enter last name" maxlength="50" />
        
        <mat-hint align="end">
        
          {{nickname.value.length}} / 50
          
        </mat-hint>
        
      </mat-form-field >
      <br/>
      <mat-form-field appearance="outline">

        <mat-label>Comments</mat-label>
      
        <textarea matInput name="CommentsBox" placeholder="Leave comments" maxlength="256" #Comments ng-trim="false"></textarea>
      
        <mat-hint align="end">{{Comments?.value?.length}} / 256</mat-hint>
      
      </mat-form-field>
      <br/>
      <mat-form-field appearance="outline">
        <mat-label>Start Date</mat-label>
        <input matInput [matDatepicker]="picker" placeholder="Choose a date">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <br/>
      <mat-radio-group>
        <mat-radio-button color="primary" name="symbol" value="1">Option 1</mat-radio-button>
        <br><br>
        <mat-radio-button color="primary" name="symbol" value="2">Option 2</mat-radio-button>
        <br><br>
        <mat-radio-button color="primary" name="symbol" value="3">Option 3</mat-radio-button>
      </mat-radio-group>
      <br/>
      <br/>
      <mat-checkbox color="primary">Unchecked</mat-checkbox>
      <br/>
      <!-- <mat-card>
        <mat-card-content>
          <h2 class="example-h2">Material Chips</h2>
          <mat-chip-list [selectable]="false">
            <mat-chip [selectable]="false">
              Chip
            </mat-chip>
            <mat-chip [selectable]="false">
              Another Chip
            </mat-chip>
          </mat-chip-list>
        </mat-card-content>
      </mat-card> -->
      <br/>
      <!-- <mat-card>
        <mat-card-content>
          <mat-progress-spinner class="example-margin" color="primary" mode="indeterminate">
          </mat-progress-spinner>
        </mat-card-content>
      </mat-card>
      
      <mat-card class="col-sm-3">
        <mat-card-content>
          
          <label>
            <mat-progress-bar class="app-progress" color="primary" mode="determinate" [value]="40" aria-label="Determinate progress-bar example"></mat-progress-bar>
          </label>
        </mat-card-content>
      </mat-card> -->
      

      
      <button class="app-button" mat-raised-button color="primary">Filled</button>
    <button class="app-button" mat-stroked-button mat-tooltip="This is a tooltip!" color="primary">Outlined</button>
    <button class="app-button" color="primary" mat-button>Text</button>
</div>
