
  <div class="modal-header">
    <h2 mat-dialog-title>{{title}}</h2>
  </div>
  <div class="modal-content">
    <div>
      <div mat-dialog-content>
        {{text}}
      </div>
      <div mat-dialog-actions class="modal-actions"> 
        <button class="app-button" mat-raised-button color="primary" type="submit" matDialogClose="confirm">Yes</button>   
        <button class="app-button" mat-raised-button color="secondary" type="button" matDialogClose="close">Cancel</button>
      </div>
    </div>
  </div>
