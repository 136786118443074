<div id="app-body" style="display: none;">
  <header style="position: fixed; width: 100%; top: 0;">
    <rsc-header
    #rscHeader
    *ngIf="mounted"
    app-key="4214d4d2-cd07-4239-a84b-75b9f358fa7b"
    main-class="main"
    full-width-layout="true"
    profile-menu="true"
    help-docs-url="https://iprospect.realogyfg.com/sites/help/Pages/"
    ></rsc-header>
  </header>

  <rsc-side-nav offset-height="50px" *ngIf="mounted">
    <div class="app-container">
      <div class="body-content">
        <main class="main-content">
          <router-outlet></router-outlet>
        </main>
      </div>
      <rsc-advanced-footer style="margin-left:58px" *ngIf="mounted" hide-help-desk-resources="true">
      </rsc-advanced-footer>
    </div>
  </rsc-side-nav>
  
  <rsc-speed-dial></rsc-speed-dial>
</div>
