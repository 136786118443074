import { App } from '../../../shared/models/app.model';
import * as fromApp from '../actions/app.actions';

export interface AppState {
    registeredApps: Array<App>;
}

const initialState = {
    registeredApps: null
};

export function appReducers(state = initialState, action: fromApp.AppAction): AppState {

    switch (action.type) {

        case fromApp.UPDATE_REGISTERED_APPS_SUCCESS: {

            const registeredApps = action.payload ? action.payload.map(app => new App().deserialize(app)) : [];

            return {
                ...state,
                registeredApps,
            };
        }

        default: {
            return state;
        }
    }
}
