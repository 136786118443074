export interface LDUserAttributes {
  iProspectOktaId: string;
  name: string;
  brandCode: string;
  iProspectPrimaryEmail: string;
  iProspectLoginID: string;
  iProspectMasterId: string;
  primaryOfficeName: string;
  primaryOfficeMasterId: string;
  offices: Array<any>;
  hasMultipleProfiles: boolean;
  companyName: string;
  companyMasterId: string;
  iProspectCompanies: Array<any>;
  isRBG: string;
  isRFG: string;
}

export enum AEP_FLAGS {
  AEP_TEST_FLAG = 'test-flag-tostart'
}

export interface FLAGS_DATA {
  flagName: AEP_FLAGS;
  flagValue: any;
}
export const AEP_FLAGS_LIST: FLAGS_DATA[] = [
  { flagName: AEP_FLAGS.AEP_TEST_FLAG, flagValue: false }
];
