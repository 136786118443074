import { AfterViewInit, Component, OnInit } from '@angular/core';
import {  AppService } from '../core/services/app.service';
import { CannySSOObject } from '../models/user';
import { CannyService } from '../services/canny.service';

declare function Canny(action:any, obj: any): any;

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit, AfterViewInit {

  cannyBoardToken: string;

  constructor(private cannyService: CannyService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.cannyService.getSSOToken().subscribe((data: CannySSOObject) => {
      Canny('render', {
        cannyBoardToken: AppService.get('cannyBoardToken'),
        basePath: '/feedback',
        ssoToken: data.cannySSOToken,
      });
    })    
  }  
}
