import { Component, OnInit, Inject, ChangeDetectionStrategy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsComponent  implements OnInit {
  constructor(@Inject(DOCUMENT) private document: any
    , private titleService: Title) {
    this.titleService.setTitle("iProspect Settings");
  }

  ngOnInit() {
  }
}
