import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppService } from '../core/services/app.service';
import { Prospect } from '../models/prospect';
@Injectable({
  providedIn: 'root',
})
export class ProspectsService {
  constructor(private http: HttpClient) {}

  getProspect() {
    
    let url = AppService.get('apiBaseURL') + 'prospects/51304770-509f-4519-8e13-172a0e1401f9';
    if(status != ''){
      url = url.concat('/',status);
    }
    return this.http.get(url);;

  }
}
