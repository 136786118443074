import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Router } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../shared/material.module';
import { SharedModule } from '../shared/shared.module';
import { AuthenticationService } from './auth/authentication.service';
import { UserService } from './services/user.service';
import { OktaAuthenticationProvider } from './auth-providers/okta-authentication.provider';
import { AuthGuard } from './guards/auth.guard';

@NgModule({
  imports: [
    CommonModule
    , RouterModule
    , BrowserAnimationsModule
    , FormsModule
    , ReactiveFormsModule
    , HttpClientModule
    , MaterialModule
    , SharedModule
  ],
  exports: [
    ],
  declarations: [
    
  ],
  providers: [
     AuthenticationService,
    OktaAuthenticationProvider,
    AuthGuard,
    UserService
  ],
  bootstrap: [
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})

export class CoreModule { }
