import { Injectable } from '@angular/core';
import * as LaunchDarkly from 'launchdarkly-js-client-sdk';
import { omit } from 'lodash';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { LDUserAttributes, AEP_FLAGS, AEP_FLAGS_LIST, FLAGS_DATA } from './user-props.state';
import { AppService } from '../services/app.service';
import { LDFlagValue } from 'launchdarkly-js-client-sdk';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagsService {
  private ldClient: LaunchDarkly.LDClient;
  flagSets$ = new BehaviorSubject<Array<FLAGS_DATA>>([]);
  featureFlags$ = this.flagSets$.asObservable();
  private flagsList = [...AEP_FLAGS_LIST];
  constructor(
  ) {
    //this.initializeLDClient();
  }

  initLaunchDarkly() {
    return new Promise((resolve, reject) => {
      this.initializeLDClient();
      resolve(true);
    });
  }

  async initializeLDClient() {
    const brandConfig = AppService.get('brandConfig');
    let brandCode = '';
    const activeUrl = window.location.origin;
    Object.keys(brandConfig).forEach((brand) => {
      if (activeUrl.includes(brand)) {
        brandCode = brandConfig[brand].brandCode;
      }
    });
    //console.log('LD config', environment.launchdarklyConfig.clientId);
    this.ldClient = LaunchDarkly.initialize(AppService.get('launchdarklyConfig').clientId, {
      anonymous: true,
      custom: {
        brandCode
      }
    });
    try {
      await this.ldClient.waitForInitialization();
      const updatedFlagsList = [...AEP_FLAGS_LIST].map((flag) => {
        return {
          ...flag,
          flagValue: this.fetchFlagStatus(flag.flagName, flag.flagValue)
        };
      });
      this.flagsList = [...updatedFlagsList];

      //Open stream to subcribe to flag changes in LD
      this.ldClient.on('change', (flags) => {
        const newFlags =
          typeof flags === 'object' && !Array.isArray(flags)
            ? Object.keys(flags).map((flag) => {
                return {
                  flagName: flag,
                  flagValue: 'current' in flags[flag] ? flags[flag].current : flags[flag]
                };
              })
            : [];
        this.flagsList = [...this.flagsList].map((flag) => {
          const flagData = newFlags.find((flg) => flg.flagName === flag.flagName);
          if (flagData) return flagData;
          return flag;
        }) as FLAGS_DATA[];
        this.flagSets$.next(flags);
      });
    } catch (err) {
      // To Do: Error handling in read-only mode story
      this.flagsList = [...AEP_FLAGS_LIST];
    }
  }

  fetchFlagStatus(flagKey, defaultValue = false) {
    return this.ldClient.variation(flagKey, defaultValue);
  }

  getLDClientFlagValue(flagKey) {
    return this.ldClient.variation(flagKey);
  }

  getFlag(flagKey: string, defaultValue: LDFlagValue = false): Observable<LDFlagValue> {
    const fetchFlag = new Subject<void>();
    this.ldClient.on(`change:${flagKey}`, () => {
        fetchFlag.next();
    });
    this.ldClient.waitUntilReady().then(() => {
        fetchFlag.next();
    });
    return fetchFlag.pipe(
        map(() => {
            return this.ldClient.variation(flagKey, defaultValue);
        })
    );
}

  async getLDClientFlags() {
    await this.ldClient.waitForInitialization();
    return this.ldClient.allFlags();
  }

  initiateIdentifiedUserLD(userProps: LDUserAttributes) {
    const user = {
      key: userProps.iProspectOktaId,
      name: userProps.name,
      custom: {
        ...omit(userProps, ['name', 'offices'])
      }
    };
    this.ldClient.identify(user, null, (err, flags) => {
      if (!err) {
        const flagLists =
          typeof flags === 'object' && !Array.isArray(flags)
            ? Object.keys(flags).map((flag) => {
                return {
                  flagName: flag,
                  flagValue: flags[flag]
                };
              })
            : [];
      }
      //TODO: handle error
    });
  }

  closeLDClientConnection() {
    this.ldClient.flush();
    this.ldClient.close();
  }
}
