import { Component, OnInit, Inject } from '@angular/core';
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from '@angular/material/snack-bar';
import { Snackbar } from '../shared/models/snackbar.model';

/**
 * `component` for displaying a **Snackbar** in our custom format. All we need to use the injected data is:
 * `@Inject(MAT_SNACK_BAR_DATA) public data: Snackbar`.
 */
@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
})
export class SnackbarComponent implements OnInit {
  constructor(
    private reference: MatSnackBarRef<SnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: Snackbar
  ) {}

  ngOnInit() {}

  // * User Interaction

  /**
   * `dispatch` event to `SnackbarService` in order to propagate this event to the caller component.
   */
  closeWithAction(): void {
    this.reference.dismissWithAction();
  }
}
