import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppService } from '../core/services/app.service';
import * as browserDetection from 'detect-browser';
import { Observable, throwError } from 'rxjs';
import { DOCUMENT } from '@angular/common';

import * as _ from 'lodash';

@Injectable()
export class AppOnLoadService {
  browsers = ['chrome', 'firefox', 'opera', 'safari', 'ie'];
  versions = [57.1, 57.1, 49.1, 11.0, 11.0];
  browser: any;
  bIndex: number;
  constructor(
    private httpClient: HttpClient,
    @Inject(DOCUMENT) private doc: any
  ) {
    this.browser = browserDetection.detect();
    this.bIndex = this.browsers.indexOf(this.browser.name.toLowerCase());
  }

  detectBrowser(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (
        this.bIndex > -1 &&
        parseFloat(this.browser.version) < this.versions[this.bIndex]
      ) {
        const redirectUrl =
        AppService.get('apiBaseURL') + AppService.get('unsupportedBrowserUrl');
        window.location.href = redirectUrl;
      } else {
        resolve();
      }
    });
  }

  docHeadInjection() {
    const params = { url: window.location.href };
    return this.httpClient
      .get(AppService.get('apiBaseURL') + 'api/theme/', { params: params })
      .subscribe(
        (result) => {
          const head = this.doc.getElementsByTagName('head')[0];

          if (_.get(result, '[0]["favicon"]', false)) {
            // Fav icon setup.
            const relDevice = { apple: 'apple-touch-icon', android: 'icon' };
            const faviconProp = [
              { type: 'apple', sizes: '57x57' },
              { type: 'apple', sizes: '76x76' },
              { type: 'apple', sizes: '80x80' },
              { type: 'apple', sizes: '152x152' },
              { type: 'apple', sizes: '167x167' },
              { type: 'apple', sizes: '180x180' },
              { type: 'android', sizes: '192x192' },
              { type: 'android', sizes: '128x128' },
            ];
            faviconProp.forEach((element) => {
              const rel = relDevice[element.type];
              const s = this.doc.createElement('link');
              s.setAttribute('rel', rel);
              s.setAttribute('sizes', element.sizes);
              s.setAttribute('href', result[0].favicon);
              head.appendChild(s);
            });
            // set page favicon
            const f = this.doc.createElement('link');
            f.setAttribute('rel', 'shortcut icon');
            f.setAttribute('href', result[0].favicon);
            head.appendChild(f);
          }
          return result;
        },
        (error) => {
          return throwError('Error during api call.');
        }
      );
  }
}
