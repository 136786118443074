import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from '../core/services/app.service';
import { CannySSOObject } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class CannyService {

  constructor(private httpClient: HttpClient) { }

  getSSOToken() {
      const headers = new HttpHeaders();
      headers.set("Access-Control-Allow-Origin", "*");
      return this.httpClient.get<CannySSOObject>(AppService.get('cannySsoUrl'), {headers});
  }
}
