import { Component, OnInit, Inject, ChangeDetectionStrategy, Input, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ChartConfiguration } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'production-graph',
  templateUrl: './production.graph.component.html',
  styleUrls: ['./production.graph.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductionGraphComponent implements OnInit {
    @Input() mlsId: string;
    public barChartLegend = true;
  public barChartPlugins = [];
  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;
  public barChartData: ChartConfiguration['data'] = {
    labels: [],
    datasets: [
      { data: [], label: 'Listings Taken',order:1, type:'bar', yAxisID:'y-axis-1', backgroundColor:'#8dafcd', borderColor:'#8dafcd'},
      { data: [], label: 'Sold Units',order:2, type:'bar', backgroundColor:'#6b8e53', borderColor:'#6b8e53' },
      { data: [], label: 'Sold Vol (000s)',order:0, type:'line', yAxisID:'y-axis-2', backgroundColor:'#ff3434', borderColor:'#ff3434' }
    ]
  };

  public barChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    layout:{},
    elements: {
        line: {
          tension: 0.5
        }
      },
      plugins:{
        tooltip: {}
      },
    scales: { 
        x: {},
        'y-axis-1': {
            type: "linear",
            title:{display:true,
              text:"Listings Taken",
            },
            display: true,
            position: "left",
            grid:{
                display: false
            },
        }, 
        'y-axis-2':{
            type: "linear",
            display: true,
            position: "right",
            title:{display:true,
              text:"Sold Vol (000s)",
              color:"#ff3434"
            },
            ticks: {
              color:"#ff3434",
              // Include a dollar sign in the ticks
              callback: function(value, index, ticks) {
                  return value.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0, })+'K';
              }
          },
            grid:{
                display: true
            }
        }
    }
  };
  constructor() {
    
    }
    
    ngOnInit(): void {
        console.log(this.mlsId);
        //TODO - API call to get data and remove hardcoding
        this.barChartData.labels=['2019', '2018', '2020', '2021', 'LTM', 'YTD'];
        this.barChartData.datasets[0].data=[30, 36, 29, 28, 14, 8];
        this.barChartData.datasets[1].data=[14, 17, 14, 16, 12, 12];
        this.barChartData.datasets[2].data=[3552, 4416, 3002, 3826, 2094, 1061];
        //this.chart.update();
    }
  }