import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { AppService } from '../core/services/app.service';
@Injectable({
  providedIn: 'root',
})
export class CoreService {
  constructor(private http: HttpClient) { }

  getUserAccount(userId) {
    const apiKey = AppService.get('coreApiKey');
    const appPoolID = AppService.get('coreAppPoolId');
    const options = {
    };
    options["apiKey"] = apiKey;
    let url = AppService.get('coreApiURL') + 'useraccount/account?field=EmailAlert&userId=' + userId + '&appPoolId=' + appPoolID;
    if (status != '') {
      url = url.concat('/', status);
    }
    return this.http.get(url, { headers: options });
  }

  upsertUserAccount(userId, email) {
    const apiKey = AppService.get('coreApiKey');
    const appPoolID = AppService.get('coreAppPoolId');
    const options = {
    };
    options["apiKey"] = apiKey;
    let url = AppService.get('coreApiURL') + 'useraccount/account';
    if (status != '') {
      url = url.concat('/', status);
    }

    var user = {
      "userId": userId,
      "appPoolId": appPoolID,
      "items": [
        {
          "field": "email",
          "value": email
        }
      ]
    }

    return this.http.patch(url, user, { headers: options });
  }
}
