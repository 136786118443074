import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { OktaAuthenticationProvider } from '../core/auth-providers/okta-authentication.provider';


@Injectable()
export class RouteResolver implements Resolve<any> {
  //inject the api service we initially implemented
   constructor(public apiService: OktaAuthenticationProvider) { }

  //resolve our list of ministries api service
   resolve() {
      return this.apiService.getUser()
   }
}