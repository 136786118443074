import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppOnLoadService } from './services/app-load.service';

export function browserDetect(appLoadService: AppOnLoadService) {
  return () => appLoadService.detectBrowser();
}

// export function dynamicGtm(appLoadService: AppOnLoadService) {
//   return () => appLoadService.docHeadInjection();
// }

@NgModule({
  imports: [HttpClientModule],
  providers: [
    AppOnLoadService,
    {
      provide: APP_INITIALIZER,
      useFactory: browserDetect,
      deps: [AppOnLoadService],
      multi: true,
    }
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: dynamicGtm,
    //   deps: [AppOnLoadService],
    //   multi: true,
    // },
  ],
})
export class AppLoadModule {}
