import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject, Subject } from 'rxjs';
import { tap } from 'rxjs/operators'
import { Injectable, Input } from '@angular/core';
import { OktaAuthenticationProvider } from './../core/auth-providers/okta-authentication.provider';
import { AppService } from './../core/services/app.service';

@Injectable()
export class Interceptor implements HttpInterceptor {
  constructor(private authService: OktaAuthenticationProvider) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.authService.getToken();
    request = request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token.accessToken}`
      },
    });

    return next.handle(request).pipe(
      tap({
       next:  (event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // do stuff with response if you want
        }
      },
      error: (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            return throwError(err);
          }
        }
      }
    })
    );
  }
}
