import * as _ from 'lodash';
import { SidenavExpansionStates } from '../constants';
import { Deserializable } from './deserializable.model';

export class SidenavState implements Deserializable<SidenavState> {

    state: SidenavExpansionStates;
    hasBackdrop: boolean;
    collapseAll: boolean;
    enableStaggeredNav: boolean;
    offsetHeight: string;
    enableCache: boolean;
    disableHover: boolean;

    deserialize(input: any): SidenavState {
        input = _.mapKeys(input, (v, k: string) => _.camelCase(k));
        Object.assign(this, input);

        return this;
    }
}
