import * as _ from 'lodash';
import { Deserializable } from './deserializable.model';

export class App implements Deserializable<App> {

    typename: string;
    appOktaId: number;
    displayName: string;
    contactName: string;
    contactEmail: string;
    secondaryName: string;
    secondaryEmail: string;
    approved: boolean;
    description: string;
    expirationDate: string;
    createdAt: string;
    updatedAt: string;
    version: number;
    deleted: boolean;
    lastChangedAt: number;

    deserialize(input: any): App {
        input = _.mapKeys(input, (v, k: string) => _.camelCase(k));
        Object.assign(this, input);

        return this;
    }
}
